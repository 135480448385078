.activeLink {
  color: black !important;
  background-color: rgba(255, 255, 255, 0.75) !important;
  border-radius: 0.5em;
  margin: auto;
  padding: 0.25em 0.75em !important;
}

.inactiveLink {
  color: white !important;
  border-radius: 0.5em;
  margin: auto;
  padding: 0.25em 0.75em !important;
}
