.container {
  display: flex;
  background-color: white;
  padding: 0.25em;
  margin: 1em;
  border-radius: 0.5em;
  width: 30em;
  max-width: 95vw;
  justify-content: left;
  align-items: center;
}

.image {
  height: 4.5em;
  border-radius: 50%;
  padding: 0.25em 0.5em;
}

.text {
  font-size: 14px;
  font-weight: 500;
  padding: 0.25em 0.5em;
}
